
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    onUnmounted,
    ref,
    watch,
  } from 'vue';
  import { Button, TabsGroup } from '@voximplant/spaceui';
  import { closeDrawer, $drawer, openDrawer } from '@/store/drawer';
  import { useStore } from 'effector-vue/composition';
  import { $users } from '@/store/users';
  import { useI18n } from 'vue-i18n';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';

  export default defineComponent({
    name: 'Drawer',
    props: {
      title: {
        type: String,
      },
      maxHeight: {
        type: String,
        default: '100%',
      },
    },
    components: {
      Button,
      TabsGroup,
    },
    setup() {
      const { t } = useI18n();
      const drawerStore = useStore($drawer);
      const users = useStore($users);
      const { isMobileScreen } = useIsMobileScreen();
      // +1 is the local user
      const usersCount = computed(() => users.value.users?.length + 1);
      const tabs = computed(() => [
        { title: t('buttons.chat'), type: 'chat' as const },
        { title: t('buttons.users'), type: 'contactList' as const, badge: usersCount },
      ]);
      const activeTab = ref(0);
      const activeSection = computed(() => tabs.value[activeTab.value].type);

      watch(
        () => drawerStore.value.section,
        (section) => {
          activeTab.value = section === 'contactList' ? 1 : 0;
        }
      );

      const changeSection = () => {
        if (activeSection.value !== drawerStore.value.section) {
          openDrawer(activeSection.value);
        }
      };

      const PopUpRef = ref<HTMLElement | null>(null);

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          closeDrawer();
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      return {
        tabs,
        drawerStore,
        closeDrawer,
        isMobileScreen,
        PopUpRef,
        activeTab,
        changeSection,
      };
    },
  });
